<template>
  <div class="content_box">
    <div class="content_box_title">全部消息</div>
    <template v-if="!empty">
      <div class="content_box_list">
        <div
          class="content_box_list_card"
          v-for="(item, index) in list"
          :key="index"
          @click="detailsBut(item.noticeId)"
        >
          <div class="content_box_list_card_img">
            <svg class="iconfont" aria-hidden="true">
              <use
                :xlink:href="
                  item.type == 'common'
                    ? '#iconweibiaoti-1_huaban11'
                    : '#iconweibiaoti-1_huaban1fuben1'
                "
              ></use>
            </svg>
            <span v-if="item.read == 0"></span>
          </div>
          <div class="content_box_list_card_text">
            <div class="content_box_list_card_text_title">
              <span class="text_title">{{ item.title }}</span>
              <span class="text_time">{{ item.createTime }}</span>
            </div>
            <div class="content_box_list_card_text_tips">
              {{
                item.type == "common" ? "您有一条新的消息" : "您有一份新的试卷"
              }}
            </div>
            <div class="content_box_list_card_text_line"></div>
          </div>
        </div>
      </div>

      <div class="foot_page">
        <div class="foot_page_space" v-if="total <= 6"></div>
        <el-pagination
          v-else
          background
          hide-on-single-page
          layout="prev, pager, next, jumper"
          :page-size="params.pageSize"
          :current-page.sync="params.pageNum"
          :total="total"
          @current-change="appraisesPageChange"
        ></el-pagination>
      </div>
    </template>
    <div v-else class="content_box_empty">
      <empty-status
        :img="require('@/assets/img/emptyIcon/wuxiaoxi@2x.png')"
        :desc="'没有新消息'"
      />
    </div>
  </div>
</template>

<script>
import { getMessageList } from "@/api/notice";
import emptyStatus from "@/components/emptyStatus/index";
export default {
  components: {
    emptyStatus,
  },
  data() {
    return {
      params: {
        pageNum: 1,
        pageSize: 6,
      },
      total: 0,
      list: [],
      empty: false,
    };
  },
  created() {
    this.getMessageList();
  },
  methods: {
    //获取消息列表
    getMessageList() {
      let that = this;
      this.$loading.show();
      getMessageList(this.params).then((res) => {
        that.$loading.hide();
        if (res.code == 200) {
          res.rows.forEach((e) => {
            if (e.createTime) {
              e.createTime = e.createTime.substring(5, 10);
            }
          });
          if (res.total <= 0) {
            this.empty = true;
          } else {
            this.empty = false;
          }
          this.list = res.rows;
          this.total = res.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //改变页
    appraisesPageChange() {
      this.getMessageList();
    },
    //点击详情
    detailsBut(id) {
      this.$emit("detailsBut", id);
    },
  },
};
</script>

<style lang="less" scoped>
.content_box {
  width: 100%;
  text-align: left;
  position: relative;
}

.content_box_title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 60px;
  height: 60px;
  text-align: left;
  padding-left: 33px;
  border-bottom: 1px solid #eeeeee;
}

.content_box_list {
  padding: 0 32px;
  width: 100%;
  height: 572px;
  & > .content_box_list_card {
    padding: 28px 0 0;
    text-align: left;
    cursor: pointer;
    & > .content_box_list_card_img {
      position: relative;
      display: inline-block;
      vertical-align: top;
      & > .iconfont {
        width: 44px;
        height: 44px;
      }
      & > span {
        position: absolute;
        width: 10px;
        height: 10px;
        background: #ff3939;
        border-radius: 50%;
        border: 2px solid #ffffff;
        left: 35px;
        top: 3px;
      }
    }
    & > .content_box_list_card_text {
      display: inline-block;
      margin-left: 13px;
      width: 842px;
      vertical-align: top;
      & > .content_box_list_card_text_title {
        & > .text_title {
          font-size: 14px;
          font-weight: 500;
          color: #333333;
          line-height: 14px;
        }
        & > .text_time {
          margin: 3px 0 0 34px;
          font-size: 12px;
          font-weight: 500;
          color: #999999;
          line-height: 12px;
        }
      }
      & > .content_box_list_card_text_tips {
        margin-top: 11px;
        height: 14px;
        font-size: 12px;
        font-weight: 500;
        color: #999999;
        line-height: 14px;
      }
      & > .content_box_list_card_text_line {
        margin-top: 23px;
        height: 1px;
        background: #eeeeee;
      }
    }
  }
}

.foot_page {
  padding: 38px 30px 26px;
  text-align: right;
  ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #0d79ff;
  }
  .foot_page_space {
    width: 100%;
    height: 32px;
  }
}

//空状态
.content_box_empty {
  height: 433px;
}
</style>